
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";

export default {
  name: "PortsSelect",
  props: {
    port_id: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:port_id"],

  setup(props) {
    const { t } = useI18n();
    const inputPort = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isPortRequired = (value) => {
      if (props.required && !value) {
        return t("rportid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "port",
      isPortRequired
    );

    const getPort = () => {
      ApiService.get("/api/ports").then(({ data }) => {
        inputPort.value.list = data;
        inputPort.value.options = data;
      });
    };
    const selectPort = (query) => {
      if (query !== "") {
        inputPort.value.loading = true;
        setTimeout(() => {
          inputPort.value.loading = false;
          inputPort.value.options = inputPort.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputPort.value.options = [];
      }
    };

    watch(
      () => props.port_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getPort());

    return {
      element_id,
      inputPort,
      errorMessage,
      getPort,
      selectPort,
    };
  },
};
